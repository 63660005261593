<template>
  <div class='flex flex-col lg:flex-row gap-y-8 lg:gap-x-8 mt-4'>
    <div class='text-2xl lg:text-3xl font-semibold uppercase w-full lg:w-1/4 pt-8'>
      {{ myPageDownloadsTitle }}
    </div>
    <div class='w-full lg:w-3/4 flex flex-row justify-between gap-x-2'>
      <div v-if='showDownloadCertificates'
        class='my-2 py-16 bg-gray-100 rounded-md text-gray-600 text-center w-1/2 border border-transparent hover:border-gray-300 cursor-pointer'
        @click='openCertificateDownload'>
        {{ downloadCertificateButtonText }}
      </div>
      <div v-if='showDownloadReceipts'
        class='my-2 py-16 bg-gray-100 rounded-md text-gray-600 text-center w-1/2 border border-transparent hover:border-gray-300 cursor-pointer'
        @click='openReceiptDownload'>
        {{ downloadReceiptButtonText }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'MyPageDownloads',
  components: {
  },
  props: [
    'showDownloadCertificates',
    'showDownloadReceipts',
  ],
  computed: {
    ...mapState('users', [
      'profile',
    ]),
    ...mapGetters('events', [
      'eventMainThemeColor',
      'showingEventMyPageConfigurations',
    ]),
    myPageDownloadsConfigs () {
      return this.showingEventMyPageConfigurations && this.showingEventMyPageConfigurations.myPageDownloadsConfigs ? this.showingEventMyPageConfigurations.myPageDownloadsConfigs : null
    },
    myPageDownloadsTitle () {
      return this.myPageDownloadsConfigs && this.myPageDownloadsConfigs.title ? this.myPageDownloadsConfigs.title : 'Download'
    },
    downloadCertificateButtonText () {
      return (this.showingEventMyPageConfigurations) ? this.showingEventMyPageConfigurations.downloadCertificateButtonText : 'Download Certificate'
    },
    downloadReceiptButtonText () {
      return (this.showingEventMyPageConfigurations) ? this.showingEventMyPageConfigurations.downloadReceiptButtonText : 'Download Receipt'
    },
  },
  methods: {
    ...mapActions('users', [
      'getProfile',
    ]),
    openCertificateDownload () {
      window.open('/certificate-download', '_blank')
    },
    openReceiptDownload () {
      window.open(`/receipt-download-file`, '_blank')
    },
  },
  mounted () {
    this.getProfile()
  }
}
</script>
